import React from 'react'

const Figure = () => {
    return (
        <div className='mt-20'>
            <section className='bg-slate-700 text-white p-10'>
                <div className='flex lg:flex-row flex-col justify-evenly items-center '>
                    <div className='text-3xl lg:w-1/5 '>
                        The Numbers Speks them selves
                    </div>

                    <div className='flex lg:flex-row flex-col items-center gap-4'>
                        <div className='lg:border-e lg:p-8 p-6'>
                            <h5 className='text-3xl font-bold'>5000 +</h5>
                            <p className='text-base font-normal'>Project Completed</p>
                        </div>


                        <div className='lg:border-e lg:p-8'>
                            <h5 className='text-3xl font-bold'>1000 +</h5>
                            <p className='text-base font-normal'>Happy Customers</p>
                        </div>
                        <div className='lg:border-e p-8'>
                            <h5 className='text-3xl font-bold'>1000 +</h5>
                            <p className='text-base font-normal'>Commercial Projects</p>
                        </div>
                        <div>
                            <h5 className='text-3xl font-bold'>1.5k +</h5>
                            <p className='text-base font-normal'>Followers</p>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    )
}

export default Figure