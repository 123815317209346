import React from 'react'

const Filter = () => {
    return (
        <div className='bg-slate-700 '>

            <form action="" className='text-white mt-20'>

                <div className='flex items-center justify-center gap-20 p-6'>

                    <input
                        type="number"
                        placeholder="Depth"
                        className="bg-black text-white rounded-md py-1 appearance-none"
                        style={{
                            backgroundColor: '#000000',
                            color: '#000000',
                            border: '1px solid #000',
                            borderRadius: '0.375rem',
                            padding: '0.25rem 0.5rem',
                        }}
                    />

                    <input
                        type="number"
                        placeholder="Width"
                        className="bg-black text-white rounded-md py-1 appearance-none"
                        style={{
                            backgroundColor: '#000000',
                            color: '#000000',
                            border: '1px solid #000',
                            borderRadius: '0.375rem',
                            padding: '0.25rem 0.5rem',
                        }}

                    />


                    <div>
                        <button id="dropdownHoverButton" data-dropdown-toggle="dropdownHover" data-dropdown-trigger="hover" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button">Dropdown hover <svg class="w-2.5 h-2.5 ms-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4" />
                        </svg>
                        </button>

                        <div id="dropdownHover" className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
                            <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownHoverButton">
                                <li>
                                    <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Dashboard</a>
                                </li>
                                <li>
                                    <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Settings</a>
                                </li>
                                <li>
                                    <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Earnings</a>
                                </li>
                                <li>
                                    <a href="#" class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Sign out</a>
                                </li>
                            </ul>
                        </div>
                    </div>






                    <input
                        type="number"
                        placeholder="Height"
                        className="bg-black text-white rounded-md py-1 appearance-none"
                        style={{
                            backgroundColor: '#000000',
                            color: '#000000',
                            border: '1px solid #000',
                            borderRadius: '0.375rem',
                            padding: '0.25rem 0.5rem',
                        }}

                    />

                    <button>
                        Apply
                    </button>

                </div>

            </form>

        </div>

    )
}

export default Filter