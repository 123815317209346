import React from 'react';
import { IoMailOpenOutline, IoLocationOutline } from "react-icons/io5";
import { CiPhone } from "react-icons/ci";

const Contact = () => {
    return (
        <React.Fragment>
            <div className='flex lg:flex-row flex-col  items-center justify-center lg:gap-44 gap-20 sm:py-6  md:py-20  bg-white text-black mt-24 mb-24'>

                <div className='flex flex-col items-center justify-center gap-4 font-medium'>
                    <a href="https://www.google.com/maps?q=29,+Second+Floor,+KB+Mall,+Sapna+Sangeeta,+Indore,+Madhya+Pradesh,+452001" target="_blank" rel="noopener noreferrer" className='flex flex-col items-center justify-center gap-4'>
                        <IoLocationOutline size={"5em"} />
                        <h5 className='text-xl'>Location</h5>
                        {/* <p className="italic">29, Second Floor...</p> */}
                    </a>
                </div>

                <div className='font-medium'>

                    <a href="mailto:adi.dubey01@gmail.com" className='flex flex-col gap-4 justify-center items-center'>
                        <IoMailOpenOutline size={"5em"} />
                        <h5 className='text-xl'>Mail</h5>
                        {/* <h5 className="italic">adi.dubey01@gmail.com</h5> */}
                    </a>
                </div>

                <div className='flex flex-col gap-4 justify-center items-center font-medium'>
                    <a href="tel:6265084050" className='flex flex-col gap-4 justify-center items-center'>
                        <CiPhone size={"5em"} />
                        <h5 className='text-xl'>Phone</h5>
                        {/* <h5 className="italic">9165079676</h5> */}
                    </a>

                </div>
            </div>
        </React.Fragment>
    )
}

export default Contact