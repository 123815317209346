import React from 'react'
import Navbar from '../components/common/Navbar'
import Filter from '../components/common/Filter'

const FloorPlans = () => {
  return (
    <React.Fragment>
      <div className='h-[100vw]'>
        <Navbar />
        <Filter />
      </div>

    </React.Fragment>
  )
}

export default FloorPlans