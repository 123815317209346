import React from 'react'
import HighlightText from './HighlightText'

const HomeCTA = () => {
    return (
        <React.Fragment>
            <div className='home-cta bg-slate-700 w-4/5 mx-auto font-inter'>

                <h2>
                    Looking For The Best Online Architect Services?
                    <a href="">
                        <HighlightText  text={"Contact us"}/>
                             
                    </a>
                </h2>

            </div>
        </React.Fragment>
    )
}

export default HomeCTA