import React, { useState, useRef } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import { getAllBanner } from '../../operations/API';
import { useEffect } from 'react';



const CarouselHome = ({ banners }) => {

    //const [banners, setBanners] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(0);
    const carouselRef = useRef(null);

    const handleVideoEnded = () => {
        // Advance to the next slide only if the current slide is a video and it has ended
        if (banners[currentSlide]?.isImage === false) {
            carouselRef.current.next();
        }
    };

    const handleSelect = (selectedIndex, e) => {
        setCurrentSlide(selectedIndex);
    };

    // useEffect(() => {
    //     const fetchBanners = async () => {
    //         try {
    //             const bannersData = await getAllBanner();
    //             setLoading(true);
    //             console.log('bannersData: ', bannersData);
    //             setBanners(bannersData);
    // setLoading(false);
    //         } catch (error) {
    //             console.error('Error fetching banners:', error);
    //         }
    //     };

    //     fetchBanners();
    // }, []);

    return (
        <React.Fragment>
            <Carousel
                pause={"hover"}
                wrap={true}
                indicators={false}
                touch={true}
                interval={2000}
                keyboard={true}
                slide={true}
                prevIcon={null} // Remove the previous button
                nextIcon={null} // Remove the next button
                prevLabel={""} // Set previous button label to empty string
                nextLabel={""} 
            >

                {banners?.map((item, index) => (

                    <Carousel.Item >
                        <div className="d-block w-100 h-auto md:h-96">

                        {
                            item.isImage ? (
                                <img
                                src={item.imageUrl}
                                alt=""
                                className="object-cover"
                                style={{ maxHeight: "700px",minHeight: "300px", maxWidth: "100vw", width: "100%", height: "100%" }}
                            />
                            ) : (
                                <video
                                controls
                                autoPlay
                                muted
                                onEnded={handleVideoEnded}
                                style={{ maxHeight: "700px", maxWidth: "100vw", width: "100%", height: "auto" }}
                            >
                                <source src={item.imageUrl} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                            )
                        }
                        </div>

                    </Carousel.Item>

                ))}
            </Carousel>

        </React.Fragment>
    )
}

export default CarouselHome