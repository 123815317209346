import axios from "axios";
//  const BASE_URL = process.env.REACT_APP_BASE_URL;
const BASE_URL = "https://atharvashree-backend.onrender.com/api/v1"


export const getAllBanner = async () => {
    try {
        const url = BASE_URL + "/getBanners"
        console.log('url: ', url);
        const result = await axios.get(url);
        console.log('result: ', result);

        return result.data.data

    } catch (error) {
        console.log('error: ', error);

    }
}

export const getTrendingHouses = async (type) => {
    try {
        const url = BASE_URL + "/getTrendingHouses"
        console.log('url: ', url);
        const result = await axios.post(url, {type:type});
        console.log('result: ', result);
        return result.data.data
    } catch (error) {
        console.log('error: ', error);
    }
}

// export const addEnquiry = async (data) => {
//     console.log('data: ', data);
//     try {
//         const url = BASE_URL + "/addEnquiry";
//         const result = await axios.post(url, data, {
//             headers: {
//                 'Content-Type': 'application/json'
//             }
//         });
//         return result.data.data
//     } catch (error) {
//         console.log('error: ', error);
        
//     }
// }

// export const getHouseElevations = async () => {
//     try {
//         const url = BASE_URL + "/getHouseElevation"
//         const result = await axios.get(url);
//         return result.data.data
//     } catch (error) {
//         console.log('error: ', error);
//     }
// }


export const addEnquiry = async (data) => {
    console.log('Data to be sent: ', data);
    try {
        const url = BASE_URL + "/addEnquiry";
        const result = await axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        console.log('Server response: ', result.data);
        return result.data.data;
    } catch (error) {
        console.error('Error: ', error.response ? error.response.data : error.message);
    }
};
