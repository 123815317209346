import React, { useEffect, useRef, useState } from 'react';
import Logo from "../../assets/images/Logoooo.jpg";
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { addEnquiry } from '../../operations/API';

const ContactForm = ({ closeForm }) => {

    const formRef = useRef(null);
    useOnClickOutside(formRef, closeForm);
    const [formData, setFormData] = useState({
        name: '',
        number: '',
        city: '',
        requirements: ''
    });

    const onChangeHandler = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const submitHandler = async () => {
        console.log("formData", formData);
        await addEnquiry(formData)
        closeForm()
    }



    return (
        <div className=''>
            {
                <div ref={formRef} className="lg:max-w-lg lg:w-full relative w-[80%] mx-auto">
                    <div className='absolute top-2 right-2' onClick={closeForm}>
                        <svg
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </div>
                    <div className="bg-gray-800 rounded-lg overflow-hidden">
                        <div className="p-8">
                            <div className=''>
                                <img src={Logo} height={100} width={120} alt="" className='mx-auto' />
                            </div>
                            <form method="POST" action="#" className="mt-8 space-y-6" onSubmit={submitHandler}>
                                <div className="rounded-md shadow-sm">
                                    <div>
                                        <label className="sr-only" htmlFor="name">Enter Your Name</label>
                                        <input
                                            placeholder="Your Name"
                                            className="appearance-none relative block w-full px-3 py-3 border border-gray-700 bg-gray-700 text-white rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                            required
                                            autoComplete="name"
                                            type="text"
                                            name="name"
                                            id="name"
                                            onChange={onChangeHandler}
                                        />
                                    </div>

                                    <div className='mt-2'>
                                        <label className="sr-only" htmlFor="name">Enter Your Number</label>
                                        <input
                                            placeholder="Your Number"
                                            className="appearance-none relative block w-full px-3 py-3 border border-gray-700 bg-gray-700 text-white rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                            required
                                            autoComplete="name"
                                            type="text"
                                            name="number"
                                            id="name"
                                            onChange={onChangeHandler}
                                        />
                                    </div>

                                    <div className='mt-2'>
                                        <label className="sr-only" htmlFor="name">Enter Your City</label>
                                        <input
                                            placeholder="Your City"
                                            className="appearance-none relative block w-full px-3 py-3 border border-gray-700 bg-gray-700 text-white rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                            required
                                            autoComplete="name"
                                            type="text"
                                            name="city"
                                            id="name"
                                            onChange={onChangeHandler}
                                        />
                                    </div>

                                    <div className='mt-2'>
                                        <label className="sr-only" htmlFor="requirements">Enter Your Requirements</label>
                                        <textarea
                                            placeholder="Your Requirements"
                                            className="appearance-none relative block w-full px-3 py-3 border border-gray-700 bg-gray-700 text-white rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                                            required
                                            autoComplete="off"
                                            name="requirements"
                                            id="requirements"
                                            rows="1"
                                            onChange={onChangeHandler}
                                        ></textarea>
                                    </div>

                                </div>

                                <div className='flex items-center justify-center'>
                                    <div
                                        class="inline-block py-2 px-6 rounded-l-xl rounded-t-xl bg-[#7747FF] hover:bg-white hover:text-[#7747FF] focus:text-[#7747FF] focus:bg-gray-200 text-gray-50 font-bold leading-loose transition duration-200"
                                    onClick={submitHandler}>
                                        Submit
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            }

        </div>
    );
};

export default ContactForm;
