// Home.js
import React, { useState, useEffect, useRef } from 'react';
import Navbar from '../components/common/Navbar';
import CarouselHome from '../components/core/CarouselHome';
import About from '../components/core/About';
import HomeCTA from '../components/core/HomeCTA';
import TrendingHouse from '../components/core/TrendingHouse';
import HouseElevation from '../components/core/HouseElevation';
import HousePlan from '../components/core/HousePlan';
import HousePlansVastu from '../components/core/HousePlansVastu';
import Commercial from '../components/core/Commercial';
import HouseDesign from '../components/core/HouseDesign';
import { Services } from '../components/core/Services';
import Features from '../components/core/Features';
import Contact from '../components/core/Contact';
import Footer from '../components/common/Footer';
import Figure from '../components/core/Figure';
import whatsapp from "../assets/images/icons/whatsapp.png";
import Loader from "../assets/images/Loader.gif";
import { getAllBanner } from "../operations/API";
import ContactForm from '../components/common/ContactForm';

const Home = () => {
    const [loading, setLoading] = useState(true);
    const [banners, setBanners] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const formRef = useRef(null);

    const openForm = () => {
        setShowForm(true);
    };

    useEffect(() => {
        const fetchBanners = async () => {
            try {
                const bannersData = await getAllBanner().then((data) => {
                    console.log('data: ', data);
                    setBanners(data);
                    setLoading(false);
                    setTimeout(() => {
                        setShowForm(true);
                    }, 12000);
                });
            } catch (error) {
                console.error('Error fetching banners:', error);
            }
        };
        fetchBanners();
    }, []);

    const closeForm = () => {
        setShowForm(false);
    };

    return (
        <React.Fragment>
            {
                loading ?
                    <div className='min-h-screen bg-white flex flex-col items-center justify-center text-black'>
                        <img src={Loader} height={300} width={300} alt="" />
                        <p className="italic font-medium text-xl">Please wait <span className="dot-animation">...</span></p>
                    </div> :
                    <div className='relative'>
                        <Navbar openForm={openForm} /> 
                        <div className='carousel mt-2'><CarouselHome banners={banners} /></div>
                        <div className='carousel'><About /></div>

                        {showForm && (
                            <React.Fragment>
                                <div
                                    ref={formRef}
                                    className={`fixed inset-0 z-40 bg-black bg-opacity-50`}
                                ></div>
                                <div className={`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 w-96`}>
                                    <ContactForm closeForm={closeForm} />
                                </div>
                            </React.Fragment>
                        )}

                        <HomeCTA />
                        <TrendingHouse />
                        <HouseElevation />
                        <HousePlan />
                        <HousePlansVastu />
                        <HouseDesign />
                        <Services />
                        <Features />
                        <Figure />
                        <Contact />
                        <Footer />

                        <div className='fixed bottom-14 left-10'>
                            <a href="https://wa.me/916265084050" target="_blank" rel="noopener noreferrer" aria-label='Go to whatsapp' order="whatsapp">
                                <img src={whatsapp} alt="WhatsApp" height={25} width={50} />
                            </a>
                        </div>
                    </div>
            }
        </React.Fragment>
    );
};

export default Home;
