import Logo from "../../assets/images/Logoooo.jpg"
import React, { useState } from 'react';
import floorPlans from "../../assets/images/icons/civil-engineering.png"
import exterior from "../../assets/images/icons/office-building.png"
import interior from "../../assets/images/icons/interior-design.png"
import professionals from "../../assets/images/icons/engineers.png"
import blogs from "../../assets/images/icons/copywriting.png"
import ContactForm from '../common/ContactForm'; // Import the ContactForm component

const Navbar = ({openForm}) => {
  const navItems = [
    { label: 'Floor Plans', link: '/', icon: floorPlans },
    { label: '3D Exterior Design', link: '/', icon: exterior },
    { label: 'Interior Design', link: '/', icon: interior },
    // { label: 'Blogs', link: '/', icon: blogs },
    { label: 'Professionals', link: '/', icon: professionals }
  ];

  const [isNavOpen, setIsNavOpen] = useState(false);
  const [showForm, setShowForm] = useState(false); 
  const [formLink, setFormLink] = useState('');


  const handleSubmit = () => {
    // Handle form submission here
  };

  const handleLinkClick = (link) => {
   // openForm(link); 
    setIsNavOpen(false); 
  };

  return (
    <div className="flex items-center justify-between border-b border-gray-400 py-2  font-semibold px-2 fixed-top bg-[#161D29]">
      <a href="/">
        <img src="https://res.cloudinary.com/dc02cdybp/image/upload/v1715601896/AtharvaShree/m3powvlq3u1z3ghhrc60.jpg"
         className="logo w-36 lg:h-16 h-12"
        alt="logo" />
      </a>
      <nav>
        <section className="MOBILE-MENU flex lg:hidden">
          <div
            className="HAMBURGER-ICON space-y-2 "
            onClick={() => setIsNavOpen((prev) => !prev)}
          >
            <span className="block h-0.5 w-8 animate-pulse bg-white"></span>
            <span className="block h-0.5 w-8 animate-pulse bg-white"></span>
            <span className="block h-0.5 w-8 animate-pulse bg-white"></span>
          </div>

          <div className={isNavOpen ? "showMenuNav" : "hideMenuNav"}>
            <div
              className="absolute top-0 right-0 px-8 py-8"
              onClick={() => setIsNavOpen(false)}
            >
              <svg
                className="h-8 w-8 text-white"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </div>
            <ul className="flex flex-col items-start justify-between min-h-[250px]">
              {navItems.map((item, index) => (
                <li className=" border-gray-400 my-8 mx-4 uppercase" key={index}>
                  <div className="flex gap-4 font-bold items-start justify-start">
                    <img src={item.icon} height={35} width={35} className='bg-white' />
                    <a href="" className="border-b" onClick={(event) => openForm()}>
                      {item.label}
                    </a>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </section>

        <ul className="DESKTOP-MENU hidden space-x-8 lg:flex">
          {navItems.map((item, index) => (
            <li className="border-gray-400 uppercase py-1" key={index}>
              <div className='flex flex-col items-center justify-center'>
                <img src={item.icon} height={25} width={25} className='bg-white' />
                <a href={item.link} onClick={(event) => handleLinkClick(item.link, event)}>
                  {item.label}
                </a>
              </div>
            </li>
          ))}
        </ul>
      </nav>

      {showForm && <ContactForm link={formLink} onSubmit={handleSubmit} />} {/* Render the ContactForm if showForm is true */}
      
      <style>{`
        .hideMenuNav {
          display: none;
        }
        .showMenuNav {
          display: block;
          position: absolute;
          width: 100%;
          height: 100vh;
          top: 0;
          left: 0;
          background: rgb(0, 8, 20);
          z-index: 10;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
        }
      `}</style>
    </div>
  );
};

export default Navbar;
