import './App.css';
import FloorPlans from './pages/FloorPlans';
import Home from "./pages/Home"
import {Routes, Route} from "react-router-dom"

function App() {
  return (
    <div className="App text-white">
     <Routes>
      <Route path="/" element={<Home />}/>
      <Route path="/floor-plans" element={<FloorPlans />} />
     </Routes>
    </div> 
  );
}

export default App;
